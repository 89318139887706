<template>
    <div class="serach-block">
        <a-space class="serach-space" direction="horizontal" :size="8">
            <a-input v-model:value="params.code" placeholder="请输入班组编码" />
            <a-input v-model:value="params.name" placeholder="请输入班组名称" />
            <Select
                v-model:value="params.leaderUsername"
                :options="leaderUsernameAndDisplayNames"
                labelKey="value"
                valueKey="key"
                placeholder="请选择班组长"
            />
        </a-space>
        <a-space direction="horizontal" :size="8">
            <a-button type="primary" @click="search">搜索</a-button>
            <a-button type="primary" @click="reset" danger>重置</a-button>
        </a-space>
    </div>
</template>

<script>
import { defineComponent, reactive, toRefs } from "vue";
import Select from "@/components/Select";
import { apiTeamSearchIndexData } from "@/api";
export default defineComponent({
    emits: ["search"],
    components: {
        Select,
    },
    setup(props, { emit }) {
        const state = reactive({
            leaderUsernameAndDisplayNames:[],
            params: {
                code: undefined,
                name: undefined,
                leaderUsername: undefined,
            },
        });

        const reset = () => {
            state.params.code = undefined;
            state.params.name = undefined;
            state.params.leaderUsername = undefined;
            search();
        };

        const search = () => {
            let { code, name } = state.params;
            state.params.code = code?.trim();
            state.params.name = name?.trim();
            emit("search", state.params);
        };

        search();

        const getIndexData = async () => {
            let res = await apiTeamSearchIndexData()
            if (res.status == "SUCCESS") {
                state.leaderUsernameAndDisplayNames = res.data.leaderUsernameAndDisplayNames || [];
            }
        };
        getIndexData();

        return {
            ...toRefs(state),
            search,
            reset,
        };
    },
});
</script>

<style lang="less" scoped>
.serach-block {
    width: 100%;
    background-color: #fff;
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    .serach-space {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        .ant-input,
        .ant-select {
            width: 160px;
        }
    }
}
</style>
