<template>
    <TeamCategory
        :data="teamCategoryOptions"
        v-model:categoryVisible="teamCategoryVisible"
        typeName="班组"
        @onSelect="onChangeTeamCategory"
    />
    <a-modal
        v-model:visible="visible"
        :title="`${upDateId ? '编辑' : '新增'}班组`"
        @cancel="handleCancel"
        width="600px"
        :footer="null"
        :destroyOnClose="true"
    >
        <a-form
            ref="formRef"
            :model="formData"
            name="basic"
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 18 }"
            autocomplete="off"
            @finish="onFinish"
        >
            <a-form-item
                label="班组编码"
                name="code"
                :rules="[
                    { required: true, message: '请输入班组编码' },
                    { validator: checkAllSpace },
                ]"
            >
                <a-input
                    v-model:value="formData.code"
                    :maxlength="100"
                    showCount
                    :disabled="Boolean(upDateId)"
                />
            </a-form-item>

            <a-form-item
                label="班组名称"
                name="name"
                :rules="[
                    { required: true, message: '请输入班组名称' },
                    { validator: checkAllSpace },
                ]"
            >
                <a-input v-model:value="formData.name" :maxlength="100" showCount />
            </a-form-item>

            <a-form-item label="班组类别" name="categoryName">
                <a-form-item>
                    <SelectInput
                        :title="formData.categoryName"
                        :onClick="() => (teamCategoryVisible = true)"
                        @onDelete="() => onChangeTeamCategory()"
                    ></SelectInput>
                </a-form-item>
            </a-form-item>

            <a-form-item :wrapper-col="{ offset: 17, span: 7 }">
                <a-button style="margin-right: 8px" @click="handleCancel">取消</a-button>
                <a-button type="primary" html-type="submit">提交</a-button>
            </a-form-item>
        </a-form>
    </a-modal>
</template>
<script>
import { defineComponent, watch, reactive, toRefs } from "vue";
import { apiTeamUpdate, apiTeamUpdateIndexData } from "@/api";
import { showMessage, checkValueIsAvailable } from "@/utils/common";
import Select from "@/components/Select";
import TeamCategory from "@/components/CategoryModal";
import SelectInput from "@/components/SelectInput";
export default defineComponent({
    props: ["upDateId"],
    emits: ["onUpdateSuccess", "update:upDateId"],
    components: {
        Select,
        TeamCategory,
        SelectInput,
    },
    setup(props, { emit }) {
        const state = reactive({
            visible: false,
            teamCategoryVisible: false,
            formRef: null,
            teamCategoryOptions: [],
            formData: {
                id: "",
                name: "",
                code: "",
                categoryCode: "",
                categoryName: "",
            },
        });

        const handleCancel = () => {
            state.visible = false;
            state.formRef.resetFields();
            emit("update:upDateId", null);
        };

        const onFinish = async () => {
            let res = await apiTeamUpdate(state.formData);
            if (res.status === "SUCCESS") {
                showMessage("success", res.msg);
                handleCancel();
                emit("onUpdateSuccess");
            }
        };

        const checkAllSpace = (rule, value) => {
            if (value && checkValueIsAvailable(value, "spaceAll")) {
                return Promise.reject("不能全是空格");
            } else {
                return Promise.resolve();
            }
        };

        const getIndexData = async id => {
            let res = await apiTeamUpdateIndexData(id);
            if (res.status === "SUCCESS") {
                const { categoryCandidate, team } = res?.data;
                state.teamCategoryOptions = categoryCandidate;
                state.formData.id = team?.id;
                state.formData.name = team?.name;
                state.formData.code = team?.code;
                state.formData.categoryName = team?.categoryName;
                state.formData.categoryCode = team?.categoryCode;
            }
        };

        const onChangeTeamCategory = arr => {
            const [value] = arr || [];
            state.formData.categoryName = value ? value?.name : "";
            state.formData.categoryCode = value ? value?.code : "";
        };

        watch(
            () => props.upDateId,
            newVal => {
                if (newVal !== null) {
                    state.visible = true;
                    getIndexData(newVal);
                } else {
                    state.visible = false;
                }
            }
        );

        return {
            ...toRefs(state),
            handleCancel,
            onFinish,
            checkAllSpace,
            onChangeTeamCategory,
        };
    },
});
</script>

<style lang="less" scoped>
.user-list {
    margin: 10px 0;
    .ant-checkbox-wrapper {
        flex-direction: row-reverse;
        color: #1890ff;
    }
    .operate-content {
        height: 50px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
}
</style>
