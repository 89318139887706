<template>
    <UserList
        v-model:userListVisible="userListVisible"
        :teamId="maintainId"
        :disableData="tableData"
        :apiParams="{
            id: maintainId,
            api: apiMemberListChoose,
        }"
        @onSelect="onSelectUser"
    />
    <a-modal
        v-model:visible="visible"
        title="成员维护"
        width="1200px"
        okText="提交"
        :destroyOnClose="true"
        @cancel="handleCancel"
        @ok="handleSubmit"
    >
        <div class="team">
            <a-descriptions title="" :column="2">
                <a-descriptions-item label="班组编码">{{ teamInfo?.code }}</a-descriptions-item>
                <a-descriptions-item label="班组类别">
                    {{ teamInfo?.categoryName }}
                </a-descriptions-item>
                <a-descriptions-item label="班组名称">{{ teamInfo?.name }}</a-descriptions-item>
            </a-descriptions>
        </div>

        <div class="user-list" v-if="maintainId">
            <a-table
                :columns="columns"
                :data-source="tableData"
                :loading="loading"
                bordered
                size="small"
                :pagination="false"
            >
                <template #bodyCell="{ column, record, index }">
                    <template v-if="column.dataIndex === 'index'">
                        {{ index + 1 }}
                    </template>
                    <template v-if="column.dataIndex === 'jobNo'">
                        {{ record?.sysUser?.jobNo }}
                    </template>
                    <template v-if="column.dataIndex === 'dept-post'">
                        <span v-for="item in record?.sysUser?.deptInfos">
                            {{ item?.sysDept?.name }} - {{ item?.sysPost?.name }}
                        </span>
                    </template>
                    <template v-if="column.dataIndex === 'operate'">
                        <a-space direction="horizontal" :size="5">
                            <a
                                @click="record.leader ? () => {} : handleDelete(index)"
                                :style="{
                                    color: record.leader ? 'rgba(0, 0, 0, 0.25)' : 'red',
                                    cursor: record.leader ? ' not-allowed' : 'defalut',
                                }"
                            >
                                删除
                            </a>
                            <a-checkbox
                                v-model:checked="record.leader"
                                @click="handleSetLeader(record.id)"
                                :disabled="record.leader"
                            >
                                设为班组长
                            </a-checkbox>
                        </a-space>
                    </template>
                </template>
                <template #title>
                    <div class="operate-content">
                        <a-button type="primary" @click="() => openAddUserModal('')">新增</a-button>
                    </div>
                </template>
            </a-table>
        </div>
    </a-modal>
</template>
<script>
import { defineComponent, watch, reactive, toRefs } from "vue";
import { apiTeamGetInfo, apiMemberListChoose, apiTeamSaveMember } from "@/api";
import Select from "@/components/Select";
import { showMessage, checkValueIsAvailable, confirmModal } from "@/utils/common";
import UserList from "@/components/UserList";
const columns = [
    {
        title: "序号",
        dataIndex: "index",
        width: 50,
    },
    {
        title: "工号",
        dataIndex: "jobNo",
    },
    {
        title: "姓名",
        dataIndex: "displayName",
    },
    {
        title: "用户名",
        dataIndex: "username",
    },
    {
        title: "部门-岗位",
        dataIndex: "dept-post",
    },
    {
        title: "操作",
        dataIndex: "operate",
        fixed: "right",
        width: 180,
    },
];

export default defineComponent({
    props: ["maintainId"],
    emits: ["eventUpdateOk", "update:maintainId"],
    components: {
        Select,
        UserList,
    },
    setup(props, { emit }) {
        const state = reactive({
            visible: false,
            userListVisible: false,
            loading: false,
            teamInfo: null,
            tableData: [],
        });

        const openAddUserModal = () => {
            state.userListVisible = true;
        };

        const handleCancel = () => {
            state.visible = false;
            emit("update:maintainId", null);
        };

        const handleSubmit = async () => {
            let res = await apiTeamSaveMember({
                teamId: props.maintainId,
                members: state.tableData,
            });
            if (res.status === "SUCCESS") {
                showMessage("success", res.msg);
                handleCancel();
                emit("eventUpdateOk");
            }
        };

        const getIndexData = async id => {
            let res = await apiTeamGetInfo(id);
            if (res.status === "SUCCESS") {
                state.teamInfo = res.data;
                state.tableData = res?.data?.members;
            }
        };

        const handleDelete = index => {
            state.tableData.splice(index, 1);
        };

        const handleSetLeader = async id => {
            state.tableData.forEach(item => {
                item.leader = item.id == id;
            });
        };

        watch(
            () => props.maintainId,
            newVal => {
                if (newVal !== null) {
                    state.visible = true;
                    newVal && getIndexData(newVal);
                } else {
                    state.visible = false;
                }
            }
        );

        const onSelectUser = users => {
            state.tableData = [
                ...state.tableData,
                ...users.map(item => ({
                    ...item,
                    userId: item.id,
                    leader: false,
                    sysUser: item,
                })),
            ];
        };

        return {
            ...toRefs(state),
            handleCancel,
            columns,
            openAddUserModal,
            handleDelete,
            handleSetLeader,
            getIndexData,
            onSelectUser,
            handleSubmit,
            apiMemberListChoose,
        };
    },
});
</script>

<style lang="less" scoped>
.user-list {
    margin: 10px 0;
    .ant-checkbox-wrapper {
        flex-direction: row-reverse;
        color: #1890ff;
    }
    .operate-content {
        height: 50px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
}
.team {
    display: flex;
    margin-bottom: 16px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size: 16px;
    .name {
        flex: 1;
    }
}
</style>
